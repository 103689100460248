<script lang="ts" setup>
import RadioGroupModal from '@/components/modals/RadioGroupModal.vue'
import type { RadioGroupModel } from '@/types'
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useModalStore } from '@/stores/modal'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { ToastService } from '@/services/toast.service'
import InstanceService from '@/services/instance.service'
import type { AssessmentV1 } from '@/types/assessment.model'
import IconUser from './icons/IconUser.vue'
const props = defineProps({
  assessment: {
    type: Object as PropType<AssessmentV1>,
    required: true
  }
})
const modalStore = useModalStore()
const featureFlagStore = useFeatureFlagsStore()
const isToastActive = computed(() => featureFlagStore.flags[FEATURE_FLAGS.TOAST_NOTIFICATION])
const administerAssessmentEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.ADMINISTER_ASSESSMENT_FLAG]
)

const toastService = new ToastService()
const assessmentId = props.assessment.assessment_id.toString()
const contentObject: RadioGroupModel[] = [
  {
    label: 'Test Online',
    value: 'navigateToTestOnline',
    description: 'Students log in to take the test online.',
    callback: () => InstanceService.navigateToTestOnline(assessmentId)
  },
  {
    label: 'Paper bubble answer sheet',
    value: 'navigateToSheetPrinting',
    description:
      'Create an answer key, print bubble sheets for student testing, then scan student sheets for grading.',
    callback: () => InstanceService.navigateToSheetPrinting(assessmentId)
  },
  {
    label: 'Go to your assessment dashboard',
    value: 'navigateToAssessmentDashboard',
    description:
      'View your assessments to set up Quick Code administrations and access ones that are ready to administer.',
    callback: () => InstanceService.navigateToAssessmentDashboard(assessmentId)
  },
  {
    label: 'View other testing options',
    value: 'navigateToOtherTestingOptions',
    description: 'See a list of other ways to administer an assessment.',
    callback: () => InstanceService.navigateToOtherTestingOptions(assessmentId)
  }
]
const showToast = () => {
  return toastService.triggerToast('Feature Coming Soon', 'info')
}
</script>

<template>
  <v-btn
    class="assign-btn"
    variant="text"
    :disabled="administerAssessmentEnabled ? false : isToastActive"
    :aria-hidden="administerAssessmentEnabled ? false : isToastActive"
    data-test="administer-button"
    @click="
      administerAssessmentEnabled
        ? modalStore.openModal(`Administer-${assessment.assessment_id.toString()}`, {
            modalOpened: true
          })
        : showToast()
    "
  >
    <template v-slot:prepend>
      <IconUser />
    </template>
    <span>Administer</span>
  </v-btn>
  <RadioGroupModal
    :uniqueId="`Administer-${assessment.assessment_id.toString()}`"
    :radioGroup="contentObject"
    title="Select Your Administration Method"
  />
</template>

<style scoped>
.assign-btn {
  color: #fff;
  background-color: #008272;
  border: 1px solid #008272;
  transition-duration: all 0.3s;
  font-size: 14px;
  line-height: 17.78px;
  font-weight: 400;
  border-radius: 4px;
  text-transform: capitalize;
  width: 100%;
  min-width: 126px;
}
/* Style for disabled buttons */
.btn:disabled {
  color: gray; /* Gray background for disabled state */
  border: 1px solid #a1a1a1; /* Optional: gray border */
  cursor: not-allowed; /* Default cursor for disabled button */
}

/* Change cursor on hover for disabled buttons */
.btn:disabled:hover {
  cursor: not-allowed; /* Cursor with circle and line through it */
}
</style>
