export const FEATURE_FLAGS = {
  FILTERED_BUTTONS: 'filtered-buttons',
  TOAST_NOTIFICATION: 'filtered-buttons',
  PIE_RENDER_FLAG: 'assessment-pie-render-flag',
  MORE_BUTTON_FLAG: 'assessment-card-more-button',
  FILTER_BY_QUESTION_COUNT: 'filter-by-question-count',
  ADMINISTER_ASSESSMENT_FLAG: 'assessment-card-administer-button',
  ASSESSMENT_CARD_VIEW_FLAG: 'assessment-card-view-or-manage',
  CREATE_ASSESSMENT_FLAG: 'create-assessment-btn',
  EDIT_DELETE_NO_CONTENT_ASSESSMENT: 'edit-delete-no-content-assessment',
  MULTI_SELECT: 'am-multi-select',
  ASSESSMENT_GRANTEE_PERMISSIONS: 'assessment-grantee-permissions',
  EDIT_DELETE_WITHIN_MORE_BTN: 'edit-delete-within-more-btn',
  AM_FOLDER_FUNCTIONALITY_FLAG: 'am-folder-functionality',
  AM_CLIENT_ENABLED_FLAG: 'am-client-enabled-flag',
  SKILLS_ASSESSMENT_FLAG: 'skills-assessment',
  ASSESSMENT_OWNER_NAME: 'assessment-owner-name',
  AM_ARCHIVE_ASSESSMENT_FLAG: 'archive-assessments'
}
