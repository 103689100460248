<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue'
import { useModalStore } from '@/stores/modal'
import type { ModalData } from '@/types'
import { mdiInformationSlabCircleOutline } from '@mdi/js'
const props = defineProps({
  uniqueId: {
    type: String,
    required: true
  },
  labelButton: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
})
const modalStore = useModalStore()
const modalData: ModalData = {
  modalHeader: {
    title: '',
    includeCloseBtnInHeader: false,
    hideHeader: true
  },
  modalSize: {
    maxHeight: 256,
    minHeight: 188,
    maxWidth: 600,
    minWidth: 320
  },
  modalActions: {
    location: 'center',
    buttons: {
      okButton: {
        label: props.labelButton,
        bgColor: '#008272',
        color: 'white',
        size: 'large',
        callback: () => {
          modalStore.closeModal(props.uniqueId)
        }
      }
    }
  }
}
</script>
<template>
  <BaseModal data-test="archive-modal" :modalData="modalData" :uniqueId="uniqueId">
    <section class="d-flex flex-column justify-center align-center pt-4">
      <v-icon
        data-test="archive-modal-icon"
        color="#008272"
        size="77"
        :icon="mdiInformationSlabCircleOutline"
      />
      <h1 class="pt-3">Only Your Assessments Were Archived</h1>
      <p class="info-modal-font">{{ message }}</p>
    </section>
  </BaseModal>
</template>
<style lang="scss">
.info-modal-font {
  font-family: 'Cerebri Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #050f2d;
}
</style>
