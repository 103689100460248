<script setup lang="ts">
import { computed, ref } from 'vue'
import { useSearchStore } from '@/stores/search'
import { onMounted } from 'vue'
import { mdiMagnify, mdiClose } from '@mdi/js'
import * as _ from 'lodash'
import { useFoldersStore } from '@/stores/folders'

const searchStore = useSearchStore()
const foldersStore = useFoldersStore()
const folderIdSelected = computed(() => foldersStore.getActiveFolderId)
const searchTerm = ref(searchStore?.searchCriteria?.searchQuery || '')
let clearIcon: HTMLElement | null

const onInput = () => {
  searchStore.resetStore()
  searchStore.updateSearchQuery(
    searchTerm.value,
    folderIdSelected.value ? folderIdSelected.value : ''
  )
}

const debouncedOnInput = _.debounce(onInput, 500)

onMounted(() => {
  clearIcon = document.querySelector('.mdi-close')
  clearIcon?.setAttribute('tabindex', '0')
  clearIcon?.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      searchTerm.value = ''
      onInput()
    }
  })
})
</script>

<template>
  <v-text-field
    v-model="searchTerm"
    class="max-width-776 mt-5"
    data-test="search-bar-test"
    type="search"
    label="Search by title, ID, keyword..."
    :prepend-inner-icon="mdiMagnify"
    variant="solo"
    clearable
    :clear-icon="mdiClose"
    clear-icon-aria-label="Clear Search"
    @keydown.enter.prevent="onInput"
    @click:clear="onInput"
    @input="debouncedOnInput"
  ></v-text-field>
</template>

<style scoped>
:deep(.v-field__clearable) {
  opacity: 1;
}
:deep(label).v-label.v-field-label {
  font-size: 14px;
  font-weight: 400;
  color: #495b8f;
  line-height: 18px;
  letter-spacing: 0;
  opacity: 1;
}
:deep(.v-input__control) {
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  border: 1px solid #7e8494;
  overflow: hidden;
}
:deep(.v-field) {
  box-shadow: none;
  display: flex;
  align-items: center;
  padding-left: 0;
}
:deep(.v-field__prepend-inner) {
  color: #495b8f;
  display: flex;
  width: 49px;
  height: 49px;
  justify-content: center;
}

:deep(.v-field__prepend-inner .v-icon) {
  background-color: #008272;
  color: white;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

:deep(.v-field__prepend-inner .v-icon .v-icon__svg) {
  font-size: 14px;
  width: 25px;
  height: 25px;
}

:deep(
    .v-field__prepend-inner > .v-icon,
    .v-field__append-inner > .v-icon,
    .v-field__clearable > .v-icon
  ) {
  opacity: 1;
}

:deep(.v-field__field) {
  height: 40px;
  color: #495b8f;
}

:deep(.v-field__input) {
  min-height: 40px;
  padding-top: 12px;
}

:deep(.v-label).v-field-label.v-field-label--floating {
  font-size: 8px;
  top: 0 !important;
}

.max-width-776 {
  max-width: 1038px;
  margin: 0 auto;
}
</style>
