<script setup lang="ts">
import { computed, type PropType } from 'vue'
import type { Grade } from '@/types'
import IconHeart from './icons/IconHeart.vue'
import StandardsModal from '@/components/modals/StandardsModal.vue'
import { ToastService } from '@/services/toast.service'
import moment from 'moment'
import { AssessmentService } from '@/services/assessment.service'
import { useAssessmentStore } from '@/stores/assessment'
import { useMultiSelectStore } from '@/stores/multiselect-assessments'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import type { AssessmentV1 } from '@/types/assessment.model'
import { useFoldersStore } from '@/stores/folders'
import CustomFolderIcon from './icons/CustomFolderIcon.vue'
import IconChevronRight from './icons/IconChevronRight.vue'
import { useModalStore } from '@/stores/modal'
import { useSearchStore } from '@/stores/search'
import { useAuthStore } from '@/stores/auth'
const props = defineProps({
  assessment: {
    type: Object as PropType<AssessmentV1>,
    required: true
  },
  index: {
    type: Number,
    required: false
  },
  isFavorited: {
    type: Boolean,
    required: false,
    default: false
  },
  showLabelAvatar: {
    type: Boolean,
    required: false,
    default: false
  },
  avatarSize: {
    type: Number,
    required: false,
    default: 0
  }
})
const authStore = useAuthStore()
const user = computed(() => authStore.user)
const isAuthorEnabled = computed(() => featureFlagStore.flags[FEATURE_FLAGS.ASSESSMENT_OWNER_NAME])
const assessmentStore = useAssessmentStore()
const modalStore = useModalStore()
const featureFlagStore = useFeatureFlagsStore()
const multiSelectStore = useMultiSelectStore()
const toastService = new ToastService()
const showAssessmentDescription = computed(() => assessmentStore.showDescription)
const multiSelectEnabled = computed(() => featureFlagStore.flags[FEATURE_FLAGS.MULTI_SELECT])
const folderFlagEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.AM_FOLDER_FUNCTIONALITY_FLAG]
)
const foldersStore = useFoldersStore()
const searchStore = useSearchStore()
const getGrades = (grades: Grade[]) => {
  return grades.map((grade) => grade.name.replace(' Grade', '')).join(', ')
}

const showToastForStandards = (message: string) => {
  return toastService.triggerToast(message, 'info')
}

const formatDate = (date: Date, format: string = 'MMM YYYY') => {
  return moment(date).format(format)
}
const toggleSelectedItemValidation = (assessment: AssessmentV1) => {
  searchStore.lastFullScreenSearchAssessmentId !== props.index?.toString() &&
    searchStore.updateLastFullScreenSearchAssessmentId(null)
  multiSelectStore.toggleSelectedItem(assessment)
}
const getCheckboxAriaLabel = () => {
  const folderName = foldersStore.getFolderNameForAssessment(props.assessment.assessment_guid)
  return folderName
    ? `folder ${folderName}, ${props.assessment.title} `
    : `${props.assessment.title}`
}

const getAuthor = (assessment: AssessmentV1) => {
  const { user: assessmentUser } = assessment
  const currentUser = user.value?.user

  return assessment.is_renaissance
    ? 'Renaissance'
    : currentUser?.id === assessmentUser.id
      ? 'Me'
      : `${assessmentUser.first_name[0]}. ${assessmentUser.last_name}`
}
const highlightFolderName = computed(() => {
  return (
    foldersStore.getToastIsBeingShown &&
    foldersStore.getAssessmentIdsToMove.some(
      (assessment) => assessment === props.assessment.assessment_guid
    )
  )
})
</script>
<template>
  <v-row align="center" class="containerHeader" no-gutters>
    <v-col cols="12" :class="{ 'pl-sm-2': assessmentStore.showFullScreenButton }">
      <div class="assessment-content pl-2 pl-sm-1 pl-lg-0">
        <h2
          tabindex="0"
          :class="{
            'body-title': true,
            'ml-6': multiSelectEnabled && assessmentStore.showFullScreenButton
          }"
        >
          <span
            v-if="
              folderFlagEnabled &&
              foldersStore.getFolderNameForAssessment(assessment.assessment_guid) &&
              !foldersStore.activeFolderId
            "
            class="folder-name"
          >
            <CustomFolderIcon class="folder-icon" />
            <span
              :class="{
                'folder-name__highlight': highlightFolderName
              }"
            >
              {{ foldersStore.getFolderNameForAssessment(assessment.assessment_guid) }}
            </span>
            <IconChevronRight class="chevron-icon" />
          </span>
          <IconHeart v-if="isFavorited" class="favorite-icon" :isFavorited="isFavorited" />
          <span data-test="assessment-title">
            <span class="visually-hidden">{{ isFavorited ? ', favorited,' : '' }}</span>
            {{ assessment.title }}
            <v-chip
              v-if="assessment.assessment_type === 'ibx' && assessment.is_draft"
              class="draft-chip"
              data-test="draft-chip"
              >Draft</v-chip
            >
          </span>
        </h2>
        <span v-if="multiSelectEnabled && assessmentStore.showFullScreenButton">
          <v-checkbox
            :data-test="`multi-select-assessment-${assessment.assessment_guid}`"
            density="compact"
            class="checkbox-option"
            color="#008272"
            :hide-details="true"
            :aria-checked="multiSelectStore.assessmentSelected(assessment)"
            :aria-label="getCheckboxAriaLabel()"
            :model-value="multiSelectStore.assessmentSelected(assessment)"
            @click.stop="toggleSelectedItemValidation(assessment)"
            @keydown.enter="toggleSelectedItemValidation(assessment)"
          />
        </span>
        <ul
          role="list"
          :class="{
            'assessment-overview': true,
            'indent-subtext': isFavorited,
            'mt-2': !assessmentStore.showFullScreenButton,
            'ml-6': multiSelectEnabled && assessmentStore.showFullScreenButton
          }"
        >
          <li role="listitem">
            <span data-test="grade-subtext">
              {{
                getGrades(assessment.grades) !== '' || getGrades(assessment.grades).length > 0
                  ? getGrades(assessment.grades)
                  : 'Grade Unknown'
              }}
            </span>
          </li>
          <li role="listitem">
            <div class="assessment-overview--dot"></div>
            <span data-test="subject-subtext">{{
              assessment.subject.name !== '' ? assessment.subject.name : 'Subject Unknown'
            }}</span>
          </li>
          <li
            role="listitem"
            :data-test="assessment.field_count > 0 ? 'withQuestions' : 'withNoQuestions'"
          >
            <div class="assessment-overview--dot"></div>
            <span data-test="question-subtext">
              {{ AssessmentService.getPluraLabel(assessment.field_count, 'Question') }}
            </span>
          </li>
          <li role="listitem">
            <div class="assessment-overview--dot"></div>
            <v-btn
              class="standards-link primary-color"
              variant="text"
              data-test="standards-link"
              @click.stop="
                assessment.standards_count
                  ? modalStore.openModal(`standards-${assessment.assessment_guid}`, {
                      modalOpened: true
                    })
                  : showToastForStandards('No Standards for this assessment')
              "
            >
              {{ AssessmentService.getPluraLabel(assessment.standards_count, 'Standard') }}
            </v-btn>
          </li>
          <li
            v-if="assessmentStore.showFullScreenButton"
            role="listitem"
            data-test="date-created-text"
          >
            <div class="assessment-overview--dot"></div>
            <span>Created {{ formatDate(new Date(assessment.created_at)) }}</span>
          </li>
          <li v-if="isAuthorEnabled" role="listitem" data-test="author-subtext">
            <div class="assessment-overview--dot"></div>
            <span class="author-subtext" :aria-label="`author ${getAuthor(assessment)}`">{{
              getAuthor(assessment)
            }}</span>
          </li>
        </ul>
        <span
          v-if="!assessmentStore.showFullScreenButton"
          class="date-label mt-5"
          data-test="assessmentId-text"
          ><span class="created-label">Created</span>
          {{ formatDate(new Date(assessment.created_at)) }}</span
        >
        <p v-if="showAssessmentDescription && assessment?.description" class="description">
          {{ assessment?.description }}
        </p>
        <StandardsModal
          v-if="modalStore.isModalOpen(`standards-${assessment.assessment_guid}`)"
          :assessmentGuid="assessment.assessment_guid"
          :uniqueId="`standards-${assessment.assessment_guid}`"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
ul > li > span {
  text-transform: capitalize;
  color: #6a78a1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.padding-left {
  padding-left: 25px;
}

.description {
  margin-top: 30px;
  max-width: 587px;
}

.text-format {
  font-family: 'Cerebri Sans Medium';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 377px;
}

.folder-name {
  text-wrap: nowrap;
  & .folder-name__highlight {
    background-color: #e5f2f1;
  }
}

.folder-icon {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  margin-top: -4px;
  margin-right: 7px;
}

.favorite-icon {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  margin-top: -2px;
  padding-left: 4px;
}

.chevron-icon {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-top: -2px;
  margin-right: 2px;
  margin-left: -2px;
}

.assessment-content {
  display: flex;
  position: relative;
  flex-direction: column;
  color: #1d2f45;
  & .checkbox-option {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.body-title {
  display: inline-block;
  color: #2b3963;
  font-family: 'Cerebri Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  margin: 0;
  text-wrap: wrap;
  word-break: break-all;
  &:focus {
    outline: none;
  }
}
.assessment-overview {
  display: flex;
  align-items: center;
  color: #495b8f;
  flex-wrap: wrap;
  & .standards-link {
    height: fit-content;
    cursor: pointer;
    padding: 0;
    color: #008272;
    text-transform: capitalize;
    letter-spacing: 0;
    text-decoration: underline;
  }
  & li {
    list-style: none;
    display: flex;
    align-items: center;
    color: #495b8f;
    font-family: 'Cerebri Sans Medium';
    & .assessment-overview--dot {
      height: 3px;
      width: 3px;
      background-color: #495b8f;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }

    & span {
      height: fit-content;
      font-size: 14px;
    }

    & span,
    .standards-link {
      line-height: 24px;
      font-family: 'Cerebri Sans';
      font-size: 14px;
      font-weight: 500;
      text-align: justify;
    }
  }
}

.draft-chip {
  background-color: #433ca9;
  color: #ffffff;
  margin-bottom: 3px;
}

:deep(.v-chip__content) {
  font-size: 10px;
  line-height: 10px;
  font-family: 'Cerebri Sans';
  font-weight: 400;
}

:deep(.v-chip.v-chip--density-default) {
  height: 16px;
  width: 36px;
}

:deep(.v-chip.v-chip--size-default) {
  padding: 0 7px;
}
.date-label {
  font-family: 'Cerebri Sans';
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #1d2f45;
}
.created-label {
  font-family: 'Cerebri Sans';
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #1d2f45;
}
</style>
