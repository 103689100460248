import { API_ENDPOINTS, axiosInstance } from '@/services/axios.service'
import { useAuthStore } from '@/stores/auth'
import { useFoldersStore } from '@/stores/folders'
import { useModalStore } from '@/stores/modal'
import { useMultiSelectStore } from '@/stores/multiselect-assessments'
import { useSearchStore } from '@/stores/search'
import { type AxiosResponse } from 'axios'
class FolderService {
  private _foldersStore: any
  private _multiSelectStore: any
  private _authStore: any
  private _modalStore: any
  private _searchStore: any

  private get foldersStore() {
    if (!this._foldersStore) {
      this._foldersStore = useFoldersStore()
    }
    return this._foldersStore
  }

  private get multiSelectStore() {
    if (!this._multiSelectStore) {
      this._multiSelectStore = useMultiSelectStore()
    }
    return this._multiSelectStore
  }

  private get authStore() {
    if (!this._authStore) {
      this._authStore = useAuthStore()
    }
    return this._authStore
  }

  private get modalStore() {
    if (!this._modalStore) {
      this._modalStore = useModalStore()
    }
    return this._modalStore
  }

  private get searchStore() {
    if (!this._searchStore) {
      this._searchStore = useSearchStore()
    }
    return this._searchStore
  }

  async fetchFolders(): Promise<AxiosResponse> {
    try {
      return axiosInstance.get(API_ENDPOINTS.FOLDERS)
    } catch (error) {
      throw new Error('Failed to get folders')
    }
  }
  async createFolder(folderName: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(API_ENDPOINTS.FOLDERS, { name: folderName })
    } catch (error) {
      throw new Error('Failed to create folder')
    }
  }
  async renameFolder(folderName: string, folderId: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`${API_ENDPOINTS.FOLDERS}/${folderId}`, { name: folderName })
    } catch (error) {
      throw new Error('Failed to rename folder')
    }
  }
  async deleteFolder(folderId: string): Promise<AxiosResponse> {
    try {
      return axiosInstance.delete(`${API_ENDPOINTS.FOLDERS}/${folderId}`)
    } catch (error) {
      throw new Error('Failed to delete folder')
    }
  }
  async moveAssessmentToFolder(
    assessmentGuids: string[],
    folderId: string
  ): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`${API_ENDPOINTS.FOLDERS}/${folderId}/assessments`, {
        assessment_guids: assessmentGuids
      })
    } catch (error) {
      throw new Error('Failed to move assessment to folder')
    }
  }
  private async archiveAssessmentApiCall(assessmentGuids: string[]): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`/assessments/archive`, assessmentGuids)
    } catch (error) {
      throw new Error('Failed to archive assessment')
    }
  }
  async unArchiveAssessmentApiCall(assessmentGuids: string[]): Promise<AxiosResponse> {
    try {
      return axiosInstance.post(`/assessments/restore`, {
        assessments_guids: assessmentGuids
      })
    } catch (error) {
      throw new Error('Failed to archive assessment')
    }
  }

  async removeAssessmentsFromFolder(
    folderId: string,
    assessmentGuids: string[]
  ): Promise<AxiosResponse> {
    try {
      return axiosInstance.delete(`${API_ENDPOINTS.FOLDERS}/${folderId}/assessments`, {
        data: {
          assessment_guids: assessmentGuids
        }
      })
    } catch (error) {
      throw new Error('Failed to move assessment to folder')
    }
  }

  async archiveAssessment(assessmentGuidsToArchive: string[]) {
    /* this function archives a set of assessments calling the API and refreshing the results */
    if (assessmentGuidsToArchive.length > 0) {
      await this.archiveAssessmentApiCall(assessmentGuidsToArchive)
      await this.searchStore.fetchSearchResults()
    }
    this.multiSelectStore.toggleAll([])
  }
  async archiveAssessmentsFromMultiSelectOption() {
    /* Archive assessments and show modal */
    const assessmentsSelected = this.multiSelectStore.currentSelectedAssessments
    const assessmentsToArchive = assessmentsSelected.filter((assessment: any) =>
      this.authStore.isAssessmentOwnedByUser(assessment.user.id)
    )
    const countNotArchivedAssessments = assessmentsSelected.length - assessmentsToArchive.length
    await this.archiveAssessment(
      assessmentsToArchive.map((assessment: any) => assessment.assessment_guid)
    )
    if (countNotArchivedAssessments > 0) {
      this.modalStore.openModal('archive-disclaimer', {
        modalOpened: true
      })
    }
    if (assessmentsToArchive.length > 0) {
      this.foldersStore.setShowArchiveToastNotification(true)
    }
  }
}
export const folderService = new FolderService()
