import { folderService } from '@/services/folder.service'
import { ResultType } from '@/types/filters.model'
import type { FolderGroup } from '@/types/folders.model'
import { MOVE_TO_FOLDER_LOCATION } from '@/util/folder.constants'
import { defineStore } from 'pinia'
type MoveToFolderOriginType = (typeof MOVE_TO_FOLDER_LOCATION)[keyof typeof MOVE_TO_FOLDER_LOCATION]

export const useFoldersStore = defineStore('foldersStore', {
  state: (): FolderGroup => ({
    folders: [],
    activeFolderId: null,
    folderNameDuplicated: '',
    assessmentsRestored: false,
    moveToFolderContent: {
      assessmentIds: [],
      origin: MOVE_TO_FOLDER_LOCATION.MULTI_SELECT,
      folderId: '0',
      showToastNotification: false,
      toastIsBeingShown: false,
      toastMessage: '',
      showArchiveToastNotification: false
    }
  }),
  getters: {
    getFolders: (state) => state.folders,
    getActiveFolder: (state) => {
      return state.folders.find((folder) => folder.folderId === state.activeFolderId) || null
    },
    getAssessmentIdsToMove: (state) => state.moveToFolderContent.assessmentIds,
    getMoveToFolderShowToastNotification: (state) =>
      state.moveToFolderContent.showToastNotification,
    getMoveToFolderToastMessage: (state) => state.moveToFolderContent.toastMessage,
    getMoveToFolderOrigin: (state) => state.moveToFolderContent.origin,
    getNameFolderToMove: (state) => {
      const folder = state.folders.find(
        (folder) => folder.folderId === state.moveToFolderContent.folderId
      )
      return folder ? folder.folderName : ''
    },
    getActiveFolderId: (state) => state.activeFolderId,
    getAssessmentsByFolderId: (state) => (folderId: string) => {
      const folder = state.folders.find((folder) => folder.folderId === folderId)
      return folder ? folder.assessmentIds : []
    },
    getToastIsBeingShown: (state) => state.moveToFolderContent.toastIsBeingShown,
    getFolderIdByAssessmentId: (state) => (assessmentId: string) => {
      const folder = state.folders.find((folder) => folder.assessmentIds.includes(assessmentId))
      return folder ? folder.folderId : null
    },
    getShowArchiveToastNotification: (state) =>
      state.moveToFolderContent.showArchiveToastNotification,
    getAssessmentsRestored: (state) => state.assessmentsRestored
  },
  actions: {
    async fetchFolders() {
      const response = await folderService.fetchFolders()
      if (response.data) {
        const folders = response.data
        this.folders = folders.map((folderItem: any) => {
          return {
            assessmentIds: Array.isArray(folderItem.assessment_guids)
              ? [...folderItem.assessment_guids]
              : [],
            folderId: folderItem.folder_id,
            folderName: folderItem.folder_name,
            folderOrder: folderItem.folder_order,
            state: 'created'
          }
        })
      }
    },
    async addFolder(folderName: string) {
      const response = await folderService.createFolder(folderName)

      if (response.data) {
        const folderItem = response.data
        this.folders.push({
          assessmentIds: [...folderItem.assessment_guids],
          folderId: folderItem.folder_id,
          folderName: folderItem.folder_name,
          folderOrder: folderItem.folder_order,
          state: 'created'
        })
      }
    },
    removeFolder(folderId: string) {
      this.folders = this.folders.filter((folder) => folder.folderId !== folderId)
    },
    setActiveFolder(folderId: string | null) {
      this.activeFolderId = folderId
    },
    changeState(state: 'renamed' | 'created', folderId: string) {
      this.folders = this.folders.map((folder) => {
        if (folder.folderId === folderId) {
          return {
            ...folder,
            state
          }
        }
        return folder
      })
    },
    async updateFolderName(folderId: string, folderName: string) {
      const response = await folderService.renameFolder(folderName, folderId)
      this.folders = this.folders.map((folder) => {
        if (folder.folderId === response.data.folder_id) {
          return {
            ...folder,
            folderName
          }
        }
        return folder
      })
    },
    async deleteFolder(folderId: string) {
      const response = await folderService.deleteFolder(folderId)
      if (response.data.status === 'ok') {
        this.folders = this.folders.filter((folder) => folder.folderId !== folderId)
      }
    },
    resetFolderNameDuplicated() {
      this.folderNameDuplicated = ''
    },
    validateFolderNameDuplicated(newFolderName: string) {
      this.folderNameDuplicated = this.folders.some(
        (folder) =>
          folder.state === 'created' &&
          folder.folderName.trim().replace(/\s+/g, '') === newFolderName.trim().replace(/\s+/g, '')
      )
        ? newFolderName
        : ''
    },
    updateAssessments(assessmentIds: string, folderId: string) {
      this.folders = this.folders.map((folder) => {
        if (folder.folderId === folderId) {
          return {
            ...folder,
            assessmentIds: folder.assessmentIds.filter(
              (assessmentId) => assessmentId !== assessmentIds
            )
          }
        }
        return folder
      })
    },
    async moveAssessmentToFolder(
      assessmentGuids: string[],
      folderId: string,
      archiveViewEnabled: boolean
    ) {
      let responseUnarchive = null
      if (archiveViewEnabled) {
        responseUnarchive = await folderService.unArchiveAssessmentApiCall(assessmentGuids)
        this.assessmentsRestored = true
      }
      if (folderId.toString() !== ResultType.ALL_RESULTS.toLowerCase()) {
        const response = await folderService.moveAssessmentToFolder(
          assessmentGuids,
          folderId.toString()
        )

        if (response.status === 200 && (!archiveViewEnabled || responseUnarchive?.status === 200)) {
          this.folders = this.folders.map((folder) => {
            if (folder.folderId === folderId) {
              return {
                ...folder,
                assessmentIds: [...response.data.assessment_guids]
              }
            }
            return folder
          })
        }
      }
    },
    async removeAssessmentFromFolder(assessmentIds: string[], folderId: string) {
      const response = await folderService.removeAssessmentsFromFolder(
        folderId.toString(),
        assessmentIds
      )
      if (response.status === 200) {
        this.folders = this.folders.map((folder) => {
          if (folder.folderId === folderId) {
            return {
              ...folder,
              assessmentIds: [...response.data.assessment_guids]
            }
          }
          return folder
        })
      }
    },
    setAssessmentRestored(assessmentsRestored: boolean) {
      this.assessmentsRestored = assessmentsRestored
    },
    setAssessmentIdsToMove(assessmentIds: string[]) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        assessmentIds
      }
    },
    setMoveToFolderOrigin(origin: MoveToFolderOriginType) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        origin
      }
    },
    setMoveToFolderId(folderId: string) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        folderId
      }
    },
    setMoveToFolderToastNotification(showToastNotification: boolean) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        showToastNotification
      }
    },
    setShowArchiveToastNotification(showArchiveToastNotification: boolean) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        showArchiveToastNotification
      }
    },
    setMoveToFolderToastMessage(toastMessage: string) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        toastMessage
      }
    },
    getFolderNameForAssessment(assessmentGuid: string) {
      const folder =
        this.getFolders.find((folder) => folder.assessmentIds.includes(assessmentGuid)) || null
      return folder?.folderName || null
    },
    setToastIsBeingShown(toastIsBeingShown: boolean) {
      this.moveToFolderContent = {
        ...this.moveToFolderContent,
        toastIsBeingShown
      }
    }
  }
})
