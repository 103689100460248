import { apiService } from '@/services/api-service'
import type { AuthUser } from '@/types/user.model'
import { defineStore } from 'pinia'
import { useCookies } from 'vue3-cookies'
import { DatadogService } from '@/services/datadog.service'
import { featureFlagService } from '@/services/feature-flag.service'
import areDeeplyEqual from '@/util/object-util'

interface AuthState {
  user: AuthUser | null
  lastPath: string | null
  localSettings: {
    hiddenModals: {
      [key: string]: { hidden: boolean }
    }
  }
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    lastPath: null,
    localSettings: {
      hiddenModals: {}
    }
  }),
  getters: {
    getUserName: (state) => state.user?.user?.name,
    getFirstName: (state) => state.user?.user?.first_name,
    getLastName: (state) => state.user?.user?.last_name,
    getUserType: (state) => state.user?.type,
    getClientName: (state) => state.user?.client?.displayName,
    isAssessmentOwnedByUser: (state) => (assessmentUserId: number) => {
      return state.user?.user?.id === assessmentUserId
    },
    getUserId: (state) => state.user?.user?.id
  },
  persist: true,
  actions: {
    isModalHidden(modalName: string) {
      return this.localSettings.hiddenModals[modalName]?.hidden || false
    },
    updateHiddenModal(modalName: string, hidden: boolean) {
      this.localSettings.hiddenModals[modalName] = { hidden }
    },
    setUser(newUser: AuthUser) {
      this.user = newUser
    },
    clearUser() {
      this.user = null
      DatadogService.clearUserFromSession()
    },
    // Load the user from storage during initialization
    async loadUserFromStorage(refresh = false) {
      if (!this.userExistsInSession()) {
        return
      }

      const userFromBE = await apiService.fetchUser()
      const userUpdated = this.user !== null && !areDeeplyEqual(this.user, userFromBE?.data)
      if (userUpdated) {
        featureFlagService.reidentify()
        window.location.reload()
      }
      if (refresh) {
        this.clearUser()
      }

      if (userFromBE) {
        this.setUser(userFromBE.data)
        DatadogService.attachUserToSession(userFromBE.data)
        if (!this.user?.instance || this.user?.instance.application !== 'allthedata') {
          // if the instance is not set redirect to the product picker page, this is handled
          // on the backend but adding some additional client logic as backup.
          DatadogService.addError(
            'No instance found for user with id: ' + this.user?.district_user_id
          )
          window.location.href = `${
            import.meta.env.VITE_AUTH_OP_URI
          }/#/product-list?error=No instance found for this user.`
        }
      } else if (!this.user?.client) {
        // if the client is not set redirect to the product picker page, this is handled
        // on the backend but adding some additional client logic as backup.
        DatadogService.addError('No client found for user with id: ' + this.user?.district_user_id)
        window.location.href = `${
          import.meta.env.VITE_AUTH_OP_URI
        }/#/product-list?error=No client found for this user.`
      } else {
        this.clearUser()
      }
    },
    userExistsInSession() {
      const cookie_manager = useCookies()
      return !!cookie_manager.cookies.get('auth')
    },
    userCanAccess() {
      if (!this.userExistsInSession()) {
        return false
      }
      // We want to check impersonation first and hold it at a higher priority.
      if (this.user?.impersonate) {
        return (
          this.user.impersonate.type === 'staff' ||
          this.user.impersonate.type === 'illuminate_employee'
        )
      }
      return this.user && (this.user.type === 'staff' || this.user.type === 'illuminate_employee')
    },
    storeLastPath() {
      const path = this.router.currentRoute.value
      if (path.fullPath !== '/' && path.fullPath !== '/filtersPreset') {
        this.lastPath = path.fullPath
      }
    },
    clearLastPath() {
      this.lastPath = null
    },
    navigateToLastPath() {
      if (this.lastPath) {
        this.router.push(this.lastPath)
      }

      this.clearLastPath()
    },
    authDisabled() {
      return (
        (import.meta.env.VITE_ENV === 'local' || import.meta.env.VITE_ENV === 'docker') &&
        import.meta.env.VITE_DISABLED_AUTH === 'true'
      )
    }
  }
})
