<script setup lang="ts">
import { type PropType, computed, onMounted, ref } from 'vue'
import { useFavoriteStore } from '@/stores/favorites'
import { useSearchStore } from '@/stores/search'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { ToastService } from '@/services/toast.service'
import { useRoute, useRouter } from 'vue-router'
import { useAssessmentStore } from '@/stores/assessment'
import AdministerAssessmentButton from './AdministerAssessmentButton.vue'
import MoreButton from './MoreButton.vue'
import IconExpand from './icons/IconExpand.vue'
import IconHeart from './icons/IconHeart.vue'
import IconShare from './icons/IconShare.vue'
import { useAssessmentPermissions } from '@/stores/assessment-permissions'
import type { AssessmentV1 } from '@/types/assessment.model'

const props = defineProps({
  assessment: {
    type: Object as PropType<AssessmentV1>,
    required: true
  },
  isFavorited: {
    type: Boolean,
    required: true,
    default: false
  }
})

const assessmentStore = useAssessmentStore()
const isFavorite = ref(false)
const favoriteStore = useFavoriteStore()
const favorites = computed(() => favoriteStore.currentFavoriteAssessments)
const searchStore = useSearchStore()
const featureFlagStore = useFeatureFlagsStore()
const moreButtonEnabled = computed(() => featureFlagStore.flags[FEATURE_FLAGS.MORE_BUTTON_FLAG])
const isToastActive = computed(() => featureFlagStore.flags[FEATURE_FLAGS.TOAST_NOTIFICATION])
const administerAssessmentEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.ADMINISTER_ASSESSMENT_FLAG]
)
const router = useRouter()
const showFullScreen = computed(() => assessmentStore.showFullScreenButton)
const assessmentPermissionsStore = useAssessmentPermissions()
const canUserShare = computed(() => assessmentPermissionsStore.canUserShare())
const canUserEdit = computed(() => assessmentPermissionsStore.canUserEdit())
const canUserAdminister = computed(() => assessmentPermissionsStore.canUserAdminister())
const showMoreButton = computed(() => {
  return (
    canUserShare.value ||
    canUserEdit.value ||
    (showFullScreen.value && moveToFolderButtonEnabled.value)
  )
})
const moveToFolderButtonEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.AM_FOLDER_FUNCTIONALITY_FLAG]
)

const toastService = new ToastService()
const navigateToFullScreen = (assessmentGuid: string) => {
  router.push({
    name: 'assessment',
    params: { id: assessmentGuid }
  })
}
const featureComingSoon = () => {
  toastService.triggerToast('Feature Coming Soon', 'info')
}
const toggleFavorite = async (assessmentGuid: string) => {
  if (searchStore.getFavoriteStatusEnabled && favorites.value.includes(assessmentGuid)) {
    searchStore.updateLastFullScreenSearchAssessmentId(null)
  }
  await favoriteStore.toggleFavorite(assessmentGuid)
  searchStore.fetchSearchResults()
}
const showAdministerButton = computed(() => {
  return (
    !props.assessment.is_draft &&
    (props.assessment.assessment_type === 'flex' || props.assessment.assessment_type === 'ibx') &&
    administerAssessmentEnabled.value &&
    !searchStore.getArchiveStatusEnabled
  )
})

onMounted(async () => {
  const route = useRoute()
  const id = route.params.id ? (route.params.id as string) : props.assessment.assessment_guid
  await assessmentPermissionsStore.getUserPermissionsWhenFlagIsEnabled(id)
})
</script>
<template>
  <ul v-if="assessment.field_count > 0" data-test="ul-buttons" class="actions">
    <li v-if="showFullScreen">
      <v-btn
        v-ripple="{ class: 'text-white' }"
        class="ghost-btn"
        variant="text"
        :disabled="!isToastActive"
        data-test="fullscreen-button"
        @click="navigateToFullScreen(assessment.assessment_guid)"
      >
        <template v-slot:prepend>
          <IconExpand />
        </template>
        <span>Fullscreen</span>
      </v-btn>
    </li>
    <li v-if="!searchStore.getArchiveStatusEnabled">
      <v-btn
        v-ripple="{ class: 'text-white' }"
        role="button"
        class="action-btn ghost-btn favorite-button"
        :class="{ 'favorite-btn': isFavorite }"
        variant="text"
        :disabled="!isToastActive"
        data-test="favorite-button"
        :aria-pressed="isFavorited"
        @click="toggleFavorite(assessment.assessment_guid)"
      >
        <template v-slot:prepend>
          <IconHeart :isFavorited="isFavorited" />
        </template>
        <span>Favorite</span>
      </v-btn>
    </li>
    <li v-if="moreButtonEnabled">
      <MoreButton
        v-if="showMoreButton"
        :assessmentId="assessment.assessment_id"
        :assessmentGuid="assessment.assessment_guid"
        :assessmentType="assessment.assessment_type"
        :assessmentUserId="assessment.user.id"
      />
    </li>
    <li v-else>
      <v-btn
        v-ripple="{ class: 'text-white' }"
        class="action-btn ghost-btn"
        variant="text"
        :disabled="!isToastActive"
        data-test="share-outside-more-button"
        @click="featureComingSoon()"
      >
        <template v-slot:prepend>
          <IconShare />
        </template>
        <span>Share</span>
      </v-btn>
    </li>
    <li v-if="showAdministerButton && canUserAdminister">
      <AdministerAssessmentButton :assessment="assessment" />
    </li>
  </ul>
</template>

<style scoped>
.actions button {
  font-family: 'Cerebri Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #050f2d;
  text-transform: capitalize;
  letter-spacing: 0;
  width: 100%;
  max-height: 36px;
}

.favorite-button {
  max-width: 71px;
}

.actions .btn:hover {
  border-color: rgba(5, 15, 45, 0.5);
}

.actions .btn:disabled {
  border: 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 24px;
  & li {
    & button {
      padding: 0 5px;
      & :deep(.v-btn__prepend) {
        margin: 0;
      }

      & span {
        padding-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 785px) {
  .actions {
    width: 100%;
    justify-content: unset;
    & li {
      flex: 1 1 auto !important;
    }
  }
  .favorite-button {
    max-width: unset;
  }
}
</style>
