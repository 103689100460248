<script setup lang="ts">
import type { IconData } from '@/types/icon.model'
import CustomIcon from './CustomIcon.vue'
const props = defineProps({
  color: {
    type: String,
    default: '#050f2d'
  }
})
const iconData: IconData[] = [
  {
    path: 'M11.8125 2.9375C12.0312 2.9375 12.25 3.15625 12.25 3.375V3.8125C12.25 4.05859 12.0312 4.25 11.8125 4.25H11.375L10.7734 13.5195C10.7461 14.2031 10.1445 14.75 9.46094 14.75H2.76172C2.07812 14.75 1.47656 14.2031 1.44922 13.5195L0.875 4.25H0.4375C0.191406 4.25 0 4.05859 0 3.8125V3.375C0 3.15625 0.191406 2.9375 0.4375 2.9375H2.67969L3.60938 1.40625C3.82812 1.05078 4.32031 0.75 4.73047 0.75H7.49219C7.90234 0.75 8.39453 1.05078 8.61328 1.40625L9.54297 2.9375H11.8125ZM4.73047 2.0625L4.21094 2.9375H8.01172L7.49219 2.0625H4.73047ZM9.46094 13.4375L10.0352 4.25H2.1875L2.76172 13.4375H9.46094Z',
    fill: props.color
  }
]
</script>
<template>
  <CustomIcon :size="13" viewBox="0 0 13 15" :d="iconData" />
</template>
