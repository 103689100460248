<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue'
import { useFoldersStore } from '@/stores/folders'
import { useModalStore } from '@/stores/modal'
import { useMultiSelectStore } from '@/stores/multiselect-assessments'
import { useSearchStore } from '@/stores/search'
import type { ModalData } from '@/types'
import { computed, reactive, ref, watch } from 'vue'
import { TOAST_MESSAGES } from '@/util/folder.constants'
import { ResultType } from '@/types/filters.model'

const foldersStore = useFoldersStore()
const searchStore = useSearchStore()
const multiSelectStore = useMultiSelectStore()
const showHint = ref(false)
const borderColor = ref('#7E8494')
const folderList = computed(() => {
  let result = []
  result.push({ title: 'All Results', value: 'all_results' })
  result = [
    ...result,
    ...foldersStore.getFolders.map((folder) => {
      return { title: folder.folderName, value: folder.folderId }
    })
  ]
  return result
})
const comboboxValue = ref<{ title: string; value: string } | null>(null)
const modalStore = useModalStore()

const moveToFolder = computed(() => foldersStore.getAssessmentIdsToMove)
const initialModalData: ModalData = {
  modalHeader: {
    title: 'Move to Folder',
    includeCloseBtnInHeader: true
  },
  modalSize: {
    minHeight: 205,
    maxWidth: 600,
    minWidth: 320
  },
  modalActions: {
    buttons: {
      okButton: {
        label: 'Move',
        bgColor: '#008272',
        color: 'white',
        size: 'large',
        textTransform: 'capitalize',
        customWidth: '67px',
        customHeight: '36px'
      },
      cancelButton: {
        label: 'Cancel',
        bgColor: '#ffffff',
        color: '#050F2D',
        customWidth: '67px',
        customHeight: '36px'
      }
    }
  }
}
let reactModalData = reactive<ModalData>(initialModalData)
const props = defineProps({
  uniqueId: {
    type: String,
    required: true
  }
})
const onComboboxChange = (value: { title: string; value: string } | null) => {
  if (value !== null) {
    showHint.value = false
    borderColor.value = '#7E8494'
  }
}
const updateFolderContent = (folderId: string | null) => {
  foldersStore.getActiveFolderId && folderId && searchStore.updateFolderId(folderId)
}
const removeAssessmentFromFolders = async (assessment: string) => {
  /* Assuming that there is only one assessment within a folder at a time, we will only retrieve the first one that is found in the list. */
  const foldersByAssessment = foldersStore.getFolders.find((folder) =>
    folder.assessmentIds.includes(assessment)
  )
  if (foldersByAssessment) {
    await foldersStore.removeAssessmentFromFolder([assessment], foldersByAssessment.folderId)
    updateFolderContent(foldersByAssessment.folderId)
  }
}
/* Before calling the API, update the Pinia store by removing the assessments from the previous folder to which they belonged. */
const updateFolderStore = (assessmentGuids: string[]) => {
  assessmentGuids.forEach((assessmentId) => {
    const folderId = foldersStore.getFolderIdByAssessmentId(assessmentId)
    if (folderId) {
      foldersStore.updateAssessments(assessmentId, folderId)
    }
  })
}

const moveToFolderCallback = async () => {
  const assessmentGuids = moveToFolder.value
  const folderIdFromComboBox = comboboxValue.value?.value as string
  if (folderIdFromComboBox !== undefined) {
    if (
      folderIdFromComboBox === ResultType.ALL_RESULTS.toLowerCase() &&
      !searchStore.getArchiveStatusEnabled
    ) {
      if (assessmentGuids.length === 1) {
        removeAssessmentFromFolders(assessmentGuids[0])
      } else if (assessmentGuids.length > 1) {
        assessmentGuids.forEach((assessmentId) => {
          removeAssessmentFromFolders(assessmentId)
        })
      }
    } else {
      updateFolderStore(assessmentGuids)
      await foldersStore.moveAssessmentToFolder(
        assessmentGuids,
        folderIdFromComboBox.toString(),
        searchStore.getArchiveStatusEnabled
      )
      updateFolderContent(foldersStore.getActiveFolderId)
    }
    multiSelectStore.toggleAll([])
    foldersStore.getActiveFolderId && searchStore.updateLastFullScreenSearchAssessmentId(null)
    modalStore.closeModal(props.uniqueId)
    foldersStore.setMoveToFolderId(folderIdFromComboBox)
    const folders = foldersStore.getFolders
    const folderName = folders.find(
      (folderItem) => folderItem.folderId === folderIdFromComboBox.toString()
    )?.folderName
    foldersStore.setMoveToFolderToastMessage(
      `${TOAST_MESSAGES.FOLDER_MOVED_TO_FOLDER} ${folderName ? folderName : 'All Results'}`
    )
    foldersStore.setMoveToFolderToastNotification(true)
  } else {
    showHint.value = true
    borderColor.value = '#bf0d00'
  }
}
watch(
  () => moveToFolder.value,
  (newValue) => {
    if (newValue.length > 0 && reactModalData.modalActions?.buttons?.okButton) {
      reactModalData.modalActions.buttons.okButton.callback = moveToFolderCallback
    }
  }
)
</script>
<template>
  <BaseModal class="base-modal" :modalData="reactModalData" :uniqueId="uniqueId">
    <p>Select the folder where you want to move this assessment.</p>
    <v-combobox
      v-model="comboboxValue"
      max-width="300"
      data-test="move-to-folder-dropdown"
      :items="folderList"
      variant="outlined"
      density="compact"
      hint="Please select a folder"
      :hide-details="!showHint"
      :persistent-hint="showHint"
      class="mt-1"
      placeholder="Select folder"
      color="#7E8494"
      :base-color="borderColor"
      :disabled="folderList.length === 0"
      @update:modelValue="onComboboxChange"
    ></v-combobox>
  </BaseModal>
</template>

<style scoped>
:deep(.v-field) {
  font-size: 14px;
}
:deep(.v-text-field .v-input__details) {
  padding: 0;
}
:deep(.v-input__details) {
  color: #bf0d00;
  font-size: 12px;
  line-height: 15.24px;
  font-family: 'Cerebri Sans';
}

:deep(.v-messages) {
  opacity: 1;
}
:deep(.v-field--variant-outlined .v-field__outline__end) {
  opacity: 1;
}
:deep(.v-field--variant-outlined .v-field__outline__start) {
  opacity: 1;
}
</style>
