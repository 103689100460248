<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import AssessmentCard from './AssessmentCard.vue'
import { useSearchStore } from '@/stores/search'
import { useFavoriteStore } from '@/stores/favorites'
import SortButton from './SortButton.vue'
import NoAssessmentFound from './NoAssessmentFound.vue'
import { onMounted } from 'vue'
import LoadingSpinner from './LoadingSpinner.vue'
import { useMultiSelectStore } from '@/stores/multiselect-assessments'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { useModalStore } from '@/stores/modal'
import CreateAssessmentButton from './CreateAssessmentButton.vue'
import { useFoldersStore } from '@/stores/folders'
import MoveToFolderModal from './modals/MoveToFolderModal.vue'
import MoveToFolderCustomToast from './MoveToFolderCustomToast.vue'
import { MOVE_TO_FOLDER_LOCATION, TOAST_MESSAGES } from '@/util/folder.constants'
import CustomTrashIcon from './icons/CustomTrashIcon.vue'
import CustomFolderIcon from './icons/CustomFolderIcon.vue'
import ArchiveModal from './modals/ArchiveModal.vue'
import { folderService } from '@/services/folder.service'
import { ResultType } from '@/types/filters.model'

const featureFlag = useFeatureFlagsStore()
const createAssessmentEnabled = computed(
  () => featureFlag.flags[FEATURE_FLAGS.CREATE_ASSESSMENT_FLAG]
)
const containerRef = ref()
const containerToastRef = ref({})
const modalStore = useModalStore()
const foldersStore = useFoldersStore()
const searchStore = useSearchStore()
const favoriteStore = useFavoriteStore()
const multiSelectStore = useMultiSelectStore()
const featureFlagStore = useFeatureFlagsStore()
const folderActive = computed(() => foldersStore.getActiveFolderId)
const searchResults = computed(() => searchStore.searchResults)
const favorites = computed(() => favoriteStore.currentFavoriteAssessments)
const resetScroll = computed(() => searchStore.resetSearchResultScroll)
const resultLimit = computed(() => searchStore.searchCriteria.resultLimit)
const cardExpanded = computed(() => searchStore.lastFullScreenSearchAssessmentId)
const multiSelectEnabled = computed(() => featureFlagStore.flags[FEATURE_FLAGS.MULTI_SELECT])
const archiveAssessmentFlagEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.AM_ARCHIVE_ASSESSMENT_FLAG]
)
const assessmentMovedToFolder = computed(() => foldersStore.getMoveToFolderShowToastNotification)
const moveToFolderOrigin = computed(() => foldersStore.getMoveToFolderOrigin)
const showArchiveToastNotification = computed(() => foldersStore.getShowArchiveToastNotification)

const moveToFolderButtonEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.AM_FOLDER_FUNCTIONALITY_FLAG]
)
const isModalStandardsOpened = computed(
  () =>
    modalStore.getListOpenedModals[0] && modalStore.getListOpenedModals[0].key.includes('standards')
)
const valueExpanded = ref(cardExpanded.value)
const showToastNotification = ref(false)
const selectedAssessmentCount = computed(() => multiSelectStore.currentSelectedAssessments.length)
const handleScroll = (event: any) => {
  if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 1) {
    searchStore.updateResultLimit(resultLimit.value + 30)
  }
}
const handleCloseNotification = () => {
  showToastNotification.value = false
}
const moveToFolder = () => {
  const assessmentGuids = multiSelectStore.currentSelectedAssessments
  foldersStore.setAssessmentIdsToMove(assessmentGuids.map((item) => item.assessment_guid))
  foldersStore.setMoveToFolderOrigin(MOVE_TO_FOLDER_LOCATION.MULTI_SELECT)
  modalStore.openModal(`move-to-folder`, {
    modalOpened: true
  })
}
const archiveAssessments = () => {
  /* Archive assessments and show modal */
  folderService.archiveAssessmentsFromMultiSelectOption()
}
const isEmptyFolder = computed(
  () =>
    (foldersStore.getActiveFolder && foldersStore.getActiveFolder.assessmentIds.length === 0) ||
    (searchStore.resultType === ResultType.ARCHIVE &&
      searchStore.currentSearchResults.hitCount === 0)
)
const assessmentsRestored = computed(() => foldersStore.getAssessmentsRestored)

watch(
  () => valueExpanded.value,
  () => {
    searchStore.updateLastFullScreenSearchAssessmentId(valueExpanded.value?.toString() || null)
  }
)
watch(
  () => cardExpanded.value,
  () => {
    if (cardExpanded.value !== valueExpanded.value) {
      valueExpanded.value = cardExpanded.value ? cardExpanded.value.toString() : null
    }
  }
)

watch(
  () => [resetScroll.value, containerRef.value],
  () => {
    if (resetScroll.value && containerRef.value) {
      containerRef.value.scrollTop = 0
      searchStore.resetSearchResultScroll = false
    }
  }
)
watch(
  () => assessmentMovedToFolder.value,
  (newValue) => {
    if (
      newValue &&
      (folderActive.value ||
        (moveToFolderOrigin.value === 'multiSelect' && folderActive.value === null))
    ) {
      showToastNotification.value = true
      foldersStore.setMoveToFolderToastNotification(false)
    }
  }
)
watch(
  () => [showArchiveToastNotification.value, modalStore.getListOpenedModals.length],
  ([newArchiveToastNotification, modalOpened]) => {
    if (!!newArchiveToastNotification && modalOpened === 0) {
      foldersStore.setMoveToFolderToastMessage(TOAST_MESSAGES.FOLDER_ARCHIVED)
      showToastNotification.value = !!newArchiveToastNotification
      foldersStore.setShowArchiveToastNotification(false)
    }
  }
)

watch(
  () => folderActive.value,
  (newValue) => {
    if (newValue) {
      searchStore.updateFolderId(newValue.toString())
    }
  }
)

watch(
  () => assessmentsRestored.value,
  (newValue) => {
    if (newValue) {
      searchStore.updateResultType(ResultType.ARCHIVE)
      foldersStore.setAssessmentRestored(false)
    }
  }
)

onMounted(() => {
  const lastSearchedAssessmentId = searchStore.lastFullScreenSearchAssessmentId
  if (lastSearchedAssessmentId) {
    const element = document.getElementById(`assessment-${lastSearchedAssessmentId}`)
    if (element) {
      element.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }
})
</script>

<template>
  <v-row
    ref="containerToastRef"
    justify="space-between"
    align="center"
    no-gutters
    class="max-width-776"
  >
    <MoveToFolderCustomToast
      :toastNotification="showToastNotification"
      @closeNotification="handleCloseNotification"
    />
    <v-col cols="12" class="results-row">
      <template v-if="multiSelectEnabled">
        <v-checkbox
          data-test="multi-select-all-checkbox"
          class="checkbox-option"
          density="compact"
          color="#008272"
          :hide-details="true"
          :aria-checked="multiSelectStore.currentSelectedAssessments.length > 0"
          :model-value="multiSelectStore.currentSelectedAssessments.length > 0"
          @click="multiSelectStore.toggleAll(searchResults.assessments)"
          @keydown.enter="multiSelectStore.toggleAll(searchResults.assessments)"
        >
          <template v-slot:label>
            <span id="checkbox-label" data-test="multi-select-asssessment-count">{{
              selectedAssessmentCount
            }}</span>
          </template>
        </v-checkbox>
      </template>
      <SortButton />
      <v-spacer></v-spacer>
      <CreateAssessmentButton v-if="createAssessmentEnabled" />
      <span v-if="selectedAssessmentCount > 0 && multiSelectEnabled" class="multi-select-ellipsis">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              density="comfortable"
              variant="text"
              class="more-ellipsis ghost-btn"
              data-test="multi-select-ellipsis-test"
              aria-label="Action"
            >
              <FontAwesomeIcon :icon="['fas', 'ellipsis-v']" />
            </v-btn>
          </template>
          <v-list role="menu">
            <v-list-item
              v-if="moveToFolderButtonEnabled"
              key="moveToFolder"
              value="moveToFolder"
              data-test="move-to-folder-ellipsis-option"
              role="menuitem"
              @click="moveToFolder"
            >
              <template v-slot:prepend>
                <CustomFolderIcon color="#050F2D" />
              </template>
              <v-list-item-title> Move to folder </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!searchStore.getArchiveStatusEnabled && archiveAssessmentFlagEnabled"
              role="button"
              data-test="archive-button-multi-select"
              @click="archiveAssessments"
            >
              <template v-slot:prepend>
                <CustomTrashIcon color="#BF0D00" />
              </template>
              <v-list-item-title class="archive-title">Archive</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-col>
  </v-row>
  <template v-if="searchResults.hitCount > 0">
    <section
      ref="containerRef"
      class="card-container max-width-776"
      data-test="card-container"
      @scroll="handleScroll"
    >
      <v-expansion-panels v-model="valueExpanded" data-test="expansion-test" variant="accordion">
        <AssessmentCard
          v-for="(card, index) in searchResults.assessments"
          :id="'assessment-' + index"
          :key="index"
          :data-test="`assessment-card-${card.assessment_guid}-test`"
          :data-test-assessment-id="card.assessment_id"
          :assessmentType="card.assessment_type"
          :assessment="card"
          :isFavorited="favorites.includes(card.assessment_guid)"
          class="assessment-card"
          :index="index"
        />
        <v-alert
          v-show="searchStore.hasErrorRetrievingSearchResults"
          type="error"
          title="An Error Has Occured"
        ></v-alert>
      </v-expansion-panels>
    </section>
  </template>

  <template v-else>
    <NoAssessmentFound v-show="!isEmptyFolder" />
    <p v-show="isEmptyFolder" class="no-assessment-found" aria-live="polite" role="alert">
      This folder is empty, or filters are hiding the content of the folder.
    </p>
  </template>
  <MoveToFolderModal :uniqueId="`move-to-folder`" />
  <LoadingSpinner v-if="!isModalStandardsOpened" />
  <ArchiveModal
    uniqueId="archive-disclaimer"
    labelButton="OK"
    message="You cannot archive assessments you didn’t create."
  />
</template>

<style scoped>
:deep(.v-list-item__prepend > .v-icon) {
  opacity: 1;
}
:deep(.v-btn__append) {
  margin: 0;
}
:deep(.v-list-item__prepend) {
  width: 30px;
}
.no-assessment-found {
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.78px;
  color: #000000;
  margin-top: 23px;
  font-family: 'Cerebri Sans medium';
}
.results-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.more-ellipsis.v-btn {
  min-width: 0;
}

.more-ellipsis {
  width: 24px !important;
  --v-btn-width: 24px !important;
}

.multi-select-ellipsis::before {
  content: '';
  border-left: 1px solid #bbc0c9;
  opacity: 0.7;
  height: 10px;
  padding-right: 12px;
}

.normalize-transform {
  text-transform: none;
}

#folder-icon {
  margin-right: 8px;
}

.checkbox-option {
  margin-left: 5px;
}

:deep(label).v-label {
  opacity: 1 !important;
}

#checkbox-label {
  font-size: 12px;
  font-family: 'Cerebri Sans';
  font-weight: 400;
  line-height: 12px;
  color: #202020;
}

#checkbox-label::after {
  content: '';
  border-left: 1px solid #bbc0c9;
  opacity: 0.7;
  height: 10px;
  padding-left: 12px;
  margin-left: 12px;
}

div.max-width-776,
section.max-width-776 {
  max-width: 776px;
  margin: 0 auto;
  position: relative;
}
.assessment-card {
  margin-bottom: 4px;
}

/* This ensures that the flex items within the sort container don't wrap */
.card-container {
  overflow-y: scroll;
  max-height: 80vh;
  position: relative;
  padding-top: 4px;
  padding-right: 3px;
  padding-left: 3px;
}
.v-expansion-panels--variant-accordion > :first-child:not(:last-child) {
  border-radius: 5px !important;
}

.v-expansion-panels--variant-accordion > :not(:first-child):not(:last-child) {
  border-radius: 5px !important;
}
</style>
